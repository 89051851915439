import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const Home = () => import("../views/home/home.vue");
const information = () => import("../views/information/information.vue");
const shot = () => import("../views/shot/shot.vue");
const texta = () => import("../views/text/texta.vue");
const textaz = () => import("../views/text/textaz.vue");
const textb = () => import("../views/textb/textb.vue");
const qianming = () => import("../views/qianming/qianming.vue");
const pay = () => import("../views/pay/pay.vue");
const order = () => import("../views/order/order.vue");
const orderdetails = () => import("../views/orderdetails/orderdetails.vue");
const orderfy = () => import("../views/orderfy/orderfy.vue");
const login = () => import("../views/login/login.vue");
// 首页
const index = () => import("../views/index/index.vue");
// 选择职业技能培训项目
const projects = () => import("../views/projects/projects.vue");
// 技能补贴申请条件
const subsidy = () => import("../views/subsidy/subsidy.vue");
// 技能补贴报名条件
const conditions = () => import("../views/conditions/conditions.vue");
// 灵活就业缴费标准
const standard = () => import("../views/standard/standard.vue");
// 灵活就业参保须知
const insurancenotice = () => import("../views/insurancenotice/insurancenotice.vue");
// 职业证书报名
const signup = () => import("../views/signup/signup.vue");
// 文章详情
const noticedetail = () => import("../views/noticedetail/noticedetail.vue");
// 社保医保
const queryss = () => import("../views/queryss/queryss.vue");
// 职业技能支付
const pays = () => import("../views/pay/pays.vue");
// 职业技能订单列表
const orders = () => import("../views/order/orders.vue");
// 职业技能订单详情
const orderdetailss = () => import("../views/orderdetails/orderdetailss.vue");
// 职业技能订单详情
const orderfys = () => import("../views/orderfy/orderfys.vue");
// 查看更多
const ckgd = () => import("../views/ckgd/ckgd.vue");
// 支付失败
const payFail = () => import("../views/pays/payFail.vue");
// 支付成功
const submit = () => import("../views/pays/submit.vue");
// 关注公众号
const follow = () => import("../views/follow/follow.vue");


const routes = [
  // {
  //   path: "/",
  //   name: 'home',
  //   component: Home
  // },
  {
    path: "/",
    name: 'index',
    component: index
  },
  {
    path: "/home",
    name: 'home',
    component: Home
  },
  {
    path: "/information",
    name: "information",
    component: information,
  },
  {
    path: "/shot",
    name: "shot",
    component: shot,
  },
  {
    path: "/texta",
    name: "texta",
    component: texta,
  },
  {
    path: "/textb",
    name: "textb",
    component: textb,
  },
  {
    path: "/qianming",
    name: "qianming",
    component: qianming,
  },
  {
    path: "/pay",
    name: "pay",
    component: pay,
  },
  // 我的订单
  {
    path: "/order",
    name: "order",
    component: order,
  },
  {
    path: "/orderdetails",
    name: "orderdetails",
    component: orderdetails,
  },
  {
    path: "/orderfy",
    name: "orderfy",
    component: orderfy,
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/index",
    name: "index",
    component: index,
  },
  {
    path: "/projects",
    name: "projects",
    component: projects,
  },
  {
    path: "/subsidy",
    name: "subsidy",
    component: subsidy,
  },
  {
    path: "/conditions",
    name: "conditions",
    component: conditions,
  },
  {
    path: "/standard",
    name: "standard",
    component: standard,
  },
  {
    path: "/insurancenotice",
    name: "insurancenotice",
    component: insurancenotice,
  },
  {
    path: "/signup",
    name: "signup",
    component: signup,
  },
  {
    path: "/noticedetail",
    name: "noticedetail",
    component: noticedetail,
  },
  {
    path: "/queryss",
    name: "queryss",
    component: queryss,
  },
  {
    path: "/pays",
    name: "pays",
    component: pays,
  },
  {
    path: "/orders",
    name: "orders",
    component: orders,
  },
  {
    path: "/orderdetailss",
    name: "orderdetailss",
    component: orderdetailss,
  },
  {
    path: "/orderfys",
    name: "orderfys",
    component: orderfys,
  },
  {
    path: "/ckgd",
    name: "ckgd",
    component: ckgd,
  },
  {
    path: "/payFail",
    name: "payFail",
    component: payFail,
  },
  {
    path: "/submit",
    name: "submit",
    component: submit,
  },
  {
    path: "/follow",
    name: "follow",
    component: follow,
  },
  {
    path: "/textaz",
    name: "textaz",
    component: textaz,
  },
];

// const VueRouterPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (to) {
//   return VueRouterPush.call(this, to).catch(err => err)
// }

const router = new VueRouter({
  // mode: 'hash',
  mode: "history",
  // base: "/lhjy/", //正事的
  routes,
});

router.beforeEach((to, from, next) => {
  // sessionStorage.setItem("id", "3646599") //线上注释了
  // sessionStorage.setItem("id", "3646690") //线上注释了
  let codes = sessionStorage.getItem("id");
  console.log("22222222222222222", codes);
  // if (sessionStorage.getItem("addse") == undefined || sessionStorage.getItem("addse") == null || sessionStorage.getItem("addse") == "") {
  // console.log(123)
  if (codes == undefined || codes == null || codes == "undefined") {
    if (to.path == '/payFail') {
      console.log("123");
      // return
      console.log('/payFail');
      window.location.replace(
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx2ea8244470e4aaf1&redirect_uri=http%3A%2F%2Flhjy.sjzydrj.net${to.path + '?id=' + to.query.id}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
      )

      sessionStorage.setItem("id", 223);
    } else if (to.path == '/signup') {
      console.log('/signup')
      window.location.replace(
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx2ea8244470e4aaf1&redirect_uri=http%3A%2F%2Flhjy.sjzydrj.net${to.path + '?goodsid=' + to.query.goodsid}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
      )
      sessionStorage.setItem("id", 223);
    } else {
      console.log("home页面");
      console.log(to.path)
      if (to.fullPath == '/?type=sb') {
        next({ path: '/home' })
      } else {
        console.log('object');
        // return
        window.location.replace(
          // `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx27607c3940366258&redirect_uri=https://health.sjzydrj.net${to.path}&response_type=code&scope=snsapi_userinfo&state=type%3Dquan%2Curl%3Dhttp%3A%2F%2Fmm.dianping.com%2Fweixin%2Faccount%2Fhome`
		  //e证e卡小程序appid
		  `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx2ea8244470e4aaf1&redirect_uri=https://lhjy.sjzydrj.net${to.path}&response_type=code&scope=snsapi_userinfo&state=type%3Dquan%2Curl%3Dhttp%3A%2F%2Fmm.dianping.com%2Fweixin%2Faccount%2Fhome`
        );
        // console.log("home页面22222222222")
      }
      // window.location.replace(
      //   `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx2ea8244470e4aaf1&redirect_uri=http%3A%2F%2Flhjy.sjzydrj.net${to.path}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
      // );
      sessionStorage.setItem("id", 224);
    }
  } else {
    // return
    console.log(to, from);
    next()
    if (to.path == '/') {
      next()
    } else {
      next()
    }
  }
})




export default router;
