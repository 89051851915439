import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    valueTitle:''
  },
  mutations: {
    //点击的状态（领卡方式）
    handCalue(state,value){
      console.log(value);
      state.valueTitle = value
      
    },
  },
  actions: {
  },
  modules: {
  }
})
