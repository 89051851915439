import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible/index.js'
import SlideVerify from 'vue-monoplasty-slide-verify';
import axios from 'axios'
import AES from './utils/AES';

import vueEsign from 'vue-esign'
Vue.use(vueEsign)

import wx from 'weixin-js-sdk'
Vue.prototype.wx = wx
Vue.config.ignoredElements = ['wx-open-launch-weapp']

Vue.prototype.$aes = AES
Vue.prototype.$axios = axios; import QS from 'qs'; //用来解决vue中post请求（详情）

Vue.prototype.qs = QS;
Vue.use(SlideVerify)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

