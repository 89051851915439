import CryptoJS from "crypto-js";
//随机生成指定数量的32进制key
export default {
  generatekey(num) {
    let library =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let key = "";
    for (let i = 0; i < num; i++) {
      let randomPoz = Math.floor(Math.random() * library.length);
      key += library.substring(randomPoz, randomPoz + 1);
    }
    return key;
  },
  //加密
  encrypt(word, keyStr) {

    let key = CryptoJS.enc.Utf8.parse('8192553d3db81630');
    let iv = CryptoJS.enc.Utf8.parse('d22b0a851e014f7b');

    let srcs = CryptoJS.enc.Utf8.parse(word);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    // console.log("-=-=-=-", encrypted.ciphertext)
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
  },
  //解密
  decrypt(word, keyStr, ivStr) {
    let key = CryptoJS.enc.Utf8.parse('8192553d3db81630');
    let iv = CryptoJS.enc.Utf8.parse('d22b0a851e014f7b');

    let base64 = CryptoJS.enc.Base64.parse(word);
    let src = CryptoJS.enc.Base64.stringify(base64);

    var decrypt = CryptoJS.AES.decrypt(src, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();

  }
};
